/*================================================================================
	Item Name: For CityApp specially define
	Version: 1.0
	Author: Moon @ 2021/11/5
================================================================================*/

// ag-grid Themes
@import '../agGrid/ag-grid';
@import '../agGrid/ag-theme-alpine';
// @import '../agGrid/ag-theme-alpine.min.css';
@import '../agGrid/ag-theme-alpine-dark';
// @import '../agGrid/ag-theme-alpine-dark.min.css';

$navbar-height: 63px;
$footer-height: 47px;
$full-screen-footer-height: 40px;

.full-screen {
	// background: #cc0000;
	// background-color: #000000;
	background-color: transparent;
	z-index: 2;
	width: 100%;
	height: calc(100% - #{$navbar-height});
	position: fixed;
	top: $navbar-height;
	// top: 300px; //debug
	left: 0;
	overflow: hidden;
  }

.cityapp {
	&.full-screen {
		@extend .full-screen;
	}
	
	&.full-screen-content-mobile {
		height: calc(100% - #{$full-screen-footer-height} - #{$footer-height});
	  }

	&.full-screen-content-desktop {
		// height: 600px;
		height: calc(100vh - 401px);
	  }  
	
	&.full-screen-footer {
		height: 100%;
	  }
	
	&.full-screen-uiloader {
	 	@extend .full-screen;
	}

	&.div-uiloader {
		display: flex;
        justify-content: center;
        align-items: center;
        height: 50vh;
	}
}

@mixin get-navbar-height {
	height: calc(100% - #{$navbar-height} - #{$footer-height});
}

// @function get-navbar-height() {
// 	@return calc(500% - #{$navbar-height});
//   }

// @function get-footer-height() {
// 	@return $footer-height;
// }  
